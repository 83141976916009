import { getEditorSentryDSN, getAppVersion, serializeError, isProduction } from '../../utils/utils'
import Raven, { RavenStatic } from 'raven-js'
import _ from 'lodash'
import { EditorReadyOptions } from '@wix/platform-editor-sdk'
import { FailedToCallApiError, FailedToSaveSiteError } from './errors'

let ravenInstance: RavenStatic
let fedopsLogger
let metaSiteId

export const getRavenInstance = () => ravenInstance

export const setRavenInstance = (raven) => {
  // for test purpose only, to init actual raven instance use only initMonitoring once in initApp
  ravenInstance = raven
}

export const getFedopsLogger = () => fedopsLogger

export const updateMetaSiteId = (msid) => {
  metaSiteId = msid
}

export const initMonitoring = (monitoring: EditorReadyOptions['monitoring']) => {
  const { createSentryMonitorForApp, createFedopsLogger } = monitoring

  fedopsLogger = createFedopsLogger()('wix-forms-editor-api')
  fedopsLogger.appLoadStarted()

  ravenInstance = createSentryMonitorForApp(getEditorSentryDSN(), {
    dataCallback: (event) => {
      const errors = _.chain(_.get(event, 'exception.values'))
        .map((exception) => (exception.message ? exception.message : exception.value))
        .compact()
        .value()

      return {
        tags: {
          ...(metaSiteId ? { metaSiteId } : {}),
          logger: 'api',
          release: getAppVersion(),
        },
        environment: isProduction() ? 'Prod' : 'Dev',
        fingerprint: errors.length > 0 ? [...errors] : ['{{ default }}'],
      }
    },
  })

  return { ravenInstance, fedopsLogger }
}

const getErrorByType = (err, options) => {
  const funcName = _.get(options, 'tags.funcName')

  if (funcName) {
    switch (funcName) {
      case 'saveSite':
        return new FailedToSaveSiteError(err)
      default:
        return new FailedToCallApiError(funcName, err)
    }
  }

  return err
}

export const handleError = (err, options = {}) => {
  console.error('handleError', err)

  const error = getErrorByType(err, options)

  if (ravenInstance) {
    ravenInstance.captureException(
      error,
      _.merge(
        {
          extra: {
            errorObject: serializeError(error),
          },
        },
        options,
      ),
    )

    ravenInstance.setTagsContext()
    ravenInstance.setExtraContext()
  }
}

export const captureBreadcrumb = (crumb: Raven.Breadcrumb) => {
  if (ravenInstance && ravenInstance.captureBreadcrumb) {
    ravenInstance.captureBreadcrumb(crumb)
  }
}
